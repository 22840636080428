import axios from 'axios'
import router from '@/router'
import { message} from 'element-ui';
// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API, // api的base_url
  timeout: 50000                  // 请求超时时间
})

// respone拦截器
service.interceptors.response.use(
  response => {
  /**
  * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
  * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
  */
	const res = response.data;
      // -5:Token 过期了;
    if (res.code === -5) {
      message({
          message: '请重新登录!',
          type: 'warning'
        })
      const _this = this
      location.href = '/login'
      router.push({path:'login'})
      return Promise.reject('error');
    }else{
        return response;
    }
  },
  error => {
    console.log(error.response)
    if(error.response.status==500&&error.response.data.message=='无权访问'){
        router.push({path:'login'})
    }
    console.log('err' + error)// for debug
    return Promise.reject(error)
  }
)

export default service
