import Vue from 'vue'
import axios from "axios"
import VueRouter from 'vue-router'
import {
  getEmpUserInfo,
  validUser
} from "@/api/router/index"
var vm = new Vue()

Vue.use(VueRouter)

Vue.prototype.$axios = axios

// 产业概况
const industrialprofile = r => require.ensure([], () => r(require('@/page/industrialprofile/industrialprofile')),
  'industrialprofile')
// 运行分析
const operationalanalysis = r => require.ensure([], () => r(require('@/page/operationalanalysis/operationalanalysis')),
  'operationalanalysis')
// 信用监测
const supervise = r => require.ensure([], () => r(require('@/page/supervise/supervise')), 'supervise')
// 企业培育
const cultivate = r => require.ensure([], () => r(require('@/page/cultivate/cultivate')), 'cultivate')
// 产品政策
const policy = r => require.ensure([], () => r(require('@/page/policy/policy')), 'policy')
// 产业人才
const talents = r => require.ensure([], () => r(require('@/page/talents/talents')), 'talents')
// 特色园区
const tspark = r => require.ensure([], () => r(require('@/page/Characteristicpark/Characteristicpark')), 'tspark')
// 产业链图谱
const chain = r => require.ensure([], () => r(require('@/page/chain/chain')), 'chain')
// 产业地图
const Amap = r => require.ensure([], () => r(require('@/page/Amap/Amap')), 'Amap')
// 企业减负
const subcompany = r => require.ensure([], () => r(require('@/page/subcompany/subcompany')), 'subcompany')
// 质量标准
const standardquality = r => require.ensure([], () => r(require('@/page/standardquality/standardquality')),
  'standardquality')
//人才服务
const talentservice = r => require.ensure([], () => r(require('@/page/talentservice/talentservice')), 'talentservice')
// 政策兑现
const cash = r => require.ensure([], () => r(require('@/page/cash/cash')), 'cash')
// 采集中心
const collection = r => require.ensure([], () => r(require('@/page/collection/collection')), 'collection')
// 研发设计
const Design = r => require.ensure([], () => r(require('@/page/Design/Design')), 'Design')
// 产业协同
const coordination = r => require.ensure([], () => r(require('@/page/coordination/coordination')), 'coordination')
// 登录
const login = r => require.ensure([], () => r(require('@/page/login')), 'login')

const routes = [{
    path: '/',
    name: 'Index',
    component: () => import('../page/index.vue'),
    // redirect: '/login',
    redirect: '/industrialprofile',
    children: [{
        path: "/industrialprofile",
        name: "industrialprofile",
        component: industrialprofile
      },
      {
        path: "/operationalanalysis",
        name: "operationalanalysis",
        component: operationalanalysis
      },
      {
        path: "/supervise",
        name: "supervise",
        component: supervise
      },
      {
        path: "/cultivate",
        name: "cultivate",
        component: cultivate
      },
      {
        path: "/policy",
        name: "policy",
        component: policy
      },
      {
        path: "/talents",
        name: "talents",
        component: talents
      },
      {
        path: "/tspark",
        name: "tspark",
        component: tspark
      },
      {
        path: "/chain",
        name: "chain",
        component: chain
      },
      {
        path: "/Amap",
        name: "Amap",
        component: Amap
      },
      {
        path: "/cash",
        name: "cash",
        component: cash
      },
      {
        path: "/subcompany",
        name: "subcompany",
        component: subcompany
      },
      {
        path: "/standardquality",
        name: "standardquality",
        component: standardquality
      },
      {
        path: "/talentservice",
        name: "talentservice",
        component: talentservice
      },

      {
        path: "collection",
        name: "collection",
        component: collection
      },
      {
        path: "Design",
        name: "Design",
        component: Design
      },
      {
        path: "coordination",
        name: "coordination",
        component: coordination
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: login
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 浙政钉免密登录
// import zydd from "gdt-jsapi";
// zydd.ready(function(code) { //页面初始化调用
// alert("钉钉生效")
// zydd.getAuthCode({corpId:'ding0f3a17cece860d68f2c783f7214b6d69'}) //corpId截取链接获取
//     .then(result => {
//       console.log(result,33333)
//       alert(result,33333333)
//       alert(result.code,3333333)
//       console.log(result.code, "浙政钉1"); //获取code
//       vw.$axios.post("/apg/auth/getTenantInfo",{authCode:result.code}).then(res=>{
//         console.log(res,'获取tenantid')
//         alert(res,3333333)
//         alert(res.data,33333333333)
//       })
//     });
// });
// zydd.ready(function(code) { //页面初始化调用
//   zydd.getAuthCode({corpId:}) //corpId截取链接获取
//     .then(result => {
//       console.log(result)
//       console.log(result.code, "浙政钉"); //获取code
//       // if (result) {

//       // //获取用户信息接口
//       //   that.$http
//       //     .post("ding/gdt-auth", {
//       //       code: result.code
//       //     })
//       //     .then(res => {
//       //     ...
//       //     });
//       // }
//     });
// });

router.beforeEach((to, from, next) => {
  if(to.query.username&&to.query.password){
    sessionStorage.setItem("usernames",to.query.username)
    sessionStorage.setItem("passwords",to.query.password)
  }
  if (to.query.employeeCode) {
    sessionStorage.setItem("employeeCode", JSON.stringify(to.query.employeeCode))
    getEmpUserInfo(to.query.employeeCode).then(res1 => {

      if (res1.data.code == 0) {
        next()
      } else {
        to.query.employeeCode = ''
        vm.$message.error('该用户没有权限,请登录!');
        next('/login')
      }
    })
  } else if (sessionStorage.getItem("employeeCode")) {
    // if (to.path != '/login') {
    //   validUser().then(req => {
    //     console.log(req)
    //     if (req.data.success) {
    //       next()
    //     } else {
    //       vm.$message.error('登录过期,请登录!');
    //       next('/login')
    //     }
    //   })
    // }
    next()
  } else {
    if (to.path == '/login') { //如果是登录页面路径，就直接next()
      next();
    } else {
      //不然就跳转到登录；
      next('/login');
    }
  }
})


export default router
