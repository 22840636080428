import fetch from "@/api/fetch"
// router路由
export function getEmpUserInfo(employeeCode){
	const data={employeeCode}
	return fetch({
		url:'/apg/auth/getEmpUserInfo?',
		method:'post',
		data
	})
}
export function validUser(){
	return fetch({
		url:'/apg/login/validUser',
		method:'get',
	})
}

