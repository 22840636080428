import Vue from 'vue'
import Vuex from 'vuex'
// import htp from './htp'
// import pth from './pth'
// import insystem from './insystem'
// import wcenter from './wcenter'
// import cfaHtp from './cfaHtp'
// import cfaPth from './cfaPth'
// import cfaInsystem from './cfaInsystem'
// import common from './common'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		// htp,  // 以房管人
		// pth,   // 以人管房
		// insystem,
		// wcenter,
		// cfaHtp,
		// cfaPth,
		// cfaInsystem,
		// common
	}
})
