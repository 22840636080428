import Vue from 'vue'
import App from './App.vue'
import "./style/common.css"
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map'
import axios from "axios"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css'
import moment from "moment"
import echarts from 'echarts'
Vue.prototype.$echarts=echarts
Vue.prototype.$moment=moment
import "swiper/dist/js/swiper.min.js"
import "swiper/dist/css/swiper.min.css"

import G2 from "@antv/g2";
import G6 from '@antv/g6';
// Vue.use(G2)
Vue.use(G6)
Vue.use(ElementUI);

//  引入dataV组件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

Vue.prototype.$axios = axios

Vue.use(BaiduMap, {
	// ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
	ak: '37IdaWFGULI4jwdF4sCGSTvXC54mO0gi'
})

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
