<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  mounted(){
	 this.ckeckBrowerVersion()
  },
  methods: {
	  ckeckBrowerVersion(){
	          let version = navigator.userAgent.toLowerCase();
	          let mac = version.indexOf('mac');
	          let os = version.indexOf('os');
			  console.log(version)
	          if(mac>0&&os>0){
	            // 苹果系统下执行的操作
				  document.documentElement.style.fontFamily="PFjx";
	          }else{
	              // windows系统下执行的操作
				  document.documentElement.style.fontFamily="microsoft yahei";
	          }
	  },
  },
}
</script>
<style lang="less">
	@import url("./assets/font/font.css");
	body{
		font-size: 16px;
		// font-family:"PFjx";
		font-family: "microsoft yahei";
	}
	#app {
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
		color: #333333;
    width: 100%;
    height: 100%;
	}
	a{
		text-decoration: none;
	}
	#box{
		width: 87%;
		min-height: 660px;
		background-color: #f7f8fa;
		padding: 20px;
	}
</style>
